<template>
  <main>
    <b-overlay :show="loading">
      <form-wizard
        color="#ff9801"
        :title="null"
        :subtitle="null"
        finish-button-text="Simpan"
        back-button-text="Sebelumnya"
        next-button-text="Selanjutnya"
        class="wizard-vertical mb-3"
        @on-complete="formSubmitted"
      >
        <!-- Pengirim -->
        <tab-content
          title="Pengirim"
          :before-change="validationForm"
        >
          <validation-observer ref="form">
            <b-row>
              <b-col
                sm="12"
                md="6"
              >
                <b-form-group
                  label="Nama Pengirim"
                  label-for="v-sender_name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="sender_name"
                    rules="required"
                  >
                    <b-form-input
                      id="v-sender_name"
                      v-model="form.sender_name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Isi Nama Pengirim"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Alamat Pengirim"
                  label-for="v-sender_address"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="sender_address"
                    rules="required"
                  >
                    <b-form-input
                      id="v-sender_address"
                      v-model="form.sender_address"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Isi Alamat Pengirim"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Telepon Pengirim"
                  label-for="v-sender_phone"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="sender_phone"
                    rules="required"
                  >
                    <b-form-input
                      id="v-sender_phone"
                      v-model="form.sender_phone"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Isi Telepon Pengirim"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Email Pengirim"
                  label-for="v-sender_email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="sender_email"
                    rules="required"
                  >
                    <b-form-input
                      id="v-sender_email"
                      v-model="form.sender_email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Isi Email Pengirim"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
              >
                <b-form-group
                  label="Latitude Pengirim"
                  label-for="v-sender_latitude"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="sender_latitude"
                    rules="required"
                  >
                    <b-form-input
                      id="v-sender_latitude"
                      v-model="form.sender_latitude"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Isi Latitude Pengirim"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Longitude Pengirim"
                  label-for="v-sender_longitude"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="sender_longitude"
                    rules="required"
                  >
                    <b-form-input
                      id="v-sender_longitude"
                      v-model="form.sender_longitude"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Isi Longitude Pengirim"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <!-- / -->
        <!-- Penerima -->
        <tab-content title="Penerima">
          <b-row>
            <b-col
              sm="12"
              md="6"
            >
              <b-form-group
                label="Nama Penerima"
                label-for="v-recipient_name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="recipient_name"
                  rules="required"
                >
                  <b-form-input
                    id="v-recipient_name"
                    v-model="form.recipient_name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Isi Nama Penerima"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Alamat Penerima"
                label-for="v-recipient_address"
              >
                <validation-provider
                  #default="{ errors }"
                  name="recipient_address"
                  rules="required"
                >
                  <b-form-input
                    id="v-recipient_address"
                    v-model="form.recipient_address"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Isi Alamat Penerima"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Telepon Penerima"
                label-for="v-recipient_phone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="recipient_phone"
                  rules="required"
                >
                  <b-form-input
                    id="v-recipient_phone"
                    v-model="form.recipient_phone"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Isi Telepon Penerima"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Email Penerima"
                label-for="v-recipient_email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="recipient_email"
                  rules="required|email"
                >
                  <b-form-input
                    id="v-recipient_email"
                    v-model="form.recipient_email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Isi Email Penerima"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <b-form-group
                label="Latitude Penerima"
                label-for="v-recipient_latitude"
              >
                <validation-provider
                  #default="{ errors }"
                  name="recipient_latitude"
                  rules="required"
                >
                  <b-form-input
                    id="v-recipient_latitude"
                    v-model="form.recipient_latitude"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Isi Latitude Penerima"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Longitude Penerima"
                label-for="v-recipient_longitude"
              >
                <validation-provider
                  #default="{ errors }"
                  name="recipient_longitude"
                  rules="required"
                >
                  <b-form-input
                    id="v-recipient_longitude"
                    v-model="form.recipient_longitude"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Isi Longitude Penerima"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </tab-content>
        <!-- / -->
        <!-- Detail Pengiriman -->
        <tab-content title="Detail Pengiriman">
          <b-row>
            <b-col
              sm="12"
              md="6"
            >
              <b-form-group
                label="Layanan Pengiriman"
                label-for="v-delivery_service"
              >
                <validation-provider
                  #default="{ errors }"
                  name="delivery_service"
                  rules="required"
                >
                  <b-form-input
                    id="v-delivery_service"
                    v-model="form.delivery_service"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Tanggal Pengiriman"
                label-for="v-delivery_date"
              >
                <validation-provider
                  #default="{ errors }"
                  name="delivery_date"
                  rules="required"
                >
                  <b-form-input
                    id="v-delivery_date"
                    v-model="form.delivery_date"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Estimasi Pengiriman"
                label-for="v-delivery_estimation"
              >
                <validation-provider
                  #default="{ errors }"
                  name="delivery_estimation"
                  rules="required"
                >
                  <b-form-input
                    id="v-delivery_estimation"
                    v-model="form.delivery_estimation"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Biaya Pengiriman"
                label-for="v-delivery_cost"
              >
                <validation-provider
                  #default="{ errors }"
                  name="delivery_cost"
                  rules="required"
                >
                  <b-form-input
                    id="v-delivery_cost"
                    v-model="form.delivery_cost"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Instruksi Khusus"
                label-for="v-special_instructions"
              >
                <validation-provider
                  #default="{ errors }"
                  name="special_instructions"
                  rules="required"
                >
                  <b-form-input
                    id="v-special_instructions"
                    v-model="form.special_instructions"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <b-form-group
                label="Asuransi"
                label-for="v-insurance"
              >
                <validation-provider
                  #default="{ errors }"
                  name="insurance"
                  rules="required"
                >
                  <b-form-checkbox
                    id="v-insurance"
                    v-model="form.insurance"
                    switch
                    inline
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Kode Promo"
                label-for="v-promo_code"
              >
                <validation-provider
                  #default="{ errors }"
                  name="promo_code"
                >
                  <b-form-input
                    id="v-promo_code"
                    v-model="form.promo_code"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Isi Kode Promo"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Metode Pembayaran"
                label-for="v-payment_method"
              >
                <validation-provider
                  #default="{ errors }"
                  name="payment_method"
                >
                  <b-form-input
                    id="v-payment_method"
                    v-model="form.payment_method"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Isi Metode Pembayaran"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </tab-content>
        <!-- / -->
        <!-- List barang -->
        <tab-content title="Detail Barang">
          <b-button
            v-if="selectedItems.length <= 0"
            variant="info"
            class="mb-2"
            @click.prevent="addList"
          >
            Add Barang
          </b-button>
          <b-table
            :fields="tableFields"
            :items="selectedItems"
            hover
            bordered
            striped
          >
            <!-- Index column -->
            <template #cell(no)="{ index }">
              {{ index + 1 }}
            </template>
            <!-- Description column -->
            <template #cell(description)="{ item }">
              <b-form-input v-model="item.description" />
            </template>
            <!-- Weight column -->
            <template #cell(weight)="{ item }">
              <b-form-input
                v-model="item.weight"
                type="number"
              />
            </template>
            <!-- Quantity column -->
            <template #cell(dimension)="{ item }">
              <b-form-input v-model="item.dimension" />
            </template>
            <!-- Quantity column -->
            <template #cell(quantity)="{ item }">
              <b-form-input
                v-model="item.quantity"
                type="number"
              />
            </template>
            <!-- Value column -->
            <template #cell(value)="{ item }">
              <b-form-input
                v-model="item.value"
                @keyup="doFormatRupiah(item)"
              />
            </template>
            <!-- Action column -->
            <template #cell(action)="{ index, item }">
              <b-button
                v-if="index >= 0"
                size="sm"
                variant="danger"
                @click.prevent="remove(index, item)"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </template>
          </b-table>
          <b-button
            v-if="selectedItems.length > 0"
            variant="primary"
            size="sm"
            @click.prevent="addList"
          >
            <feather-icon icon="PlusCircleIcon" />
          </b-button>
        </tab-content>
        <!-- / -->
      </form-wizard>
    </b-overlay>
  </main>
</template>
<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import flatPickr from 'vue-flatpickr-component'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BForm,
  BRow,
  BCol,
  BFormSelect,
  BCard,
  BOverlay,
  BTooltip,
  BButton,
  BTable,
  BFormTextarea,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import '@core/scss/vue/libs/vue-flatpicker.scss'

extend('required', {
  ...required,
  message: 'This field is required',
})
extend('positive', value => value > 0)
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    FormWizard,
    TabContent,
    BFormSelect,
    BRow,
    BForm,
    flatPickr,
    BOverlay,
    BTable,
    BTooltip,
    BCard,
    BButton,
    BFormTextarea,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormCheckbox,
  },
  data() {
    return {
      selectedItems: [],
      tableFields: [
        { key: 'no', label: 'No' },
        { key: 'description', label: 'Deskripsi' },
        { key: 'weight', label: 'Berat (kg)' },
        { key: 'dimension', label: 'Dimensi (cm)' },
        { key: 'quantity', label: 'Jumlah' },
        { key: 'value', label: 'Nilai Barang' },
        { key: 'action', label: 'Aksi' },
      ],
      form: {
        sender_name: '',
        sender_address: '',
        sender_phone: '',
        sender_email: '',
        sender_latitude: '',
        sender_longitude: '',
        recipient_name: '',
        recipient_address: '',
        recipient_phone: '',
        recipient_email: '',
        recipient_latitude: '',
        recipient_longitude: '',
        delivery_service: '',
        delivery_date: '',
        delivery_estimation: '',
        delivery_cost: '',
        special_instructions: '',
        insurance: false,
        promo_code: '',
        payment_method: '',
      },
      loading: false,
    }
  },
  created() {
    // const userData = JSON.parse(localStorage.getItem("userData"));
    // if (userData.id) {
    //   this.form.karyawan_id = userData.karyawan.id;
    // }
    if (this.$route.params.id) {
      // if edit action
      this.id = this.$route.params.id
      this.$store.dispatch('shippingOrder/getPeminjamanById', this.$route.params.id)
        .then(penerimaanBarang => {
          if (!penerimaanBarang) {
            this.displayError({
              message: 'Data tidak ditemukan',
            })
            setTimeout(() => {
              this.$router.push('/shipping-order')
            }, 1000)
          } else {
            // Assign fetched data to form fields for editing
            this.form = {
              ...penerimaanBarang,
              type: 'edit',
            }
            this.selectedItems = [
              ...penerimaanBarang.items.map(item => ({
                description: item.description,
                weight: item.weight,
                dimension: item.dimension,
                quantity: item.quantity,
                value: item.value,
              })),
            ]
          }
        })
        .catch(error => {
          this.displayError(error)
          setTimeout(() => {
            this.$router.push('/shipping-order')
          }, 1000)
        })
    }
  },

  mounted() {
    // const userData = JSON.parse(localStorage.getItem("userData"));
    // if (userData.id) {
    //   this.form.karyawan_id = userData.karyawan.id;
    // }
    // this.getsales_id();
    // if (this.$route.params.id) {
    //   this.getAnalisis();
    // }
    // this.gettipe();
  },
  methods: {
    addList() {
      this.selectedItems.push({
        description: '',
        weight: '',
        dimension: '',
        quantity: '',
        value: '',
      })
    },
    remove(index, item) {
      this.selectedItems.splice(index, 1)
    },
    doFormatRupiah(item) {
      const reverse = item.value.toString().split('').reverse().join('')
      const ribuan = reverse.match(/\d{1,3}/g)
      const result = ribuan.join('.').split('').reverse().join('')
      item.value = result
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    async formSubmitted() {
      // You can access this.form to get the form data.
      const payload = {
        ...this.form,
        items: this.selectedItems,
      }
      this.loading = true
      await this.$store.dispatch('shippingOrder/savePeminjaman', payload).then(res => {
        console.log('res', res)
        this.loading = false
        this.$router.push('/shipping-order')
        this.displaySuccess({
          message: 'Order Pengiriman berhasil disimpan!',
        })
      }).catch(error => {
        this.loading = false
        this.displayError(error)
        return false
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
